import { ref, type Ref } from "vue";
import { authenticationService, meetingService } from "@/core/services";
import {
  IntranetIdEnum,
  type NotNestedMeetingRole,
  type OwnMeetingParticipant,
  type NotNestedDiploma,
} from "@sgdf/client";
import { useQuery } from "@tanstack/vue-query";

export const useMe = () => {
  const userStore = useUserStore();
  const { meId } = storeToRefs(userStore);

  if (!meId.value) {
    authenticationService.getUser().then((user) => {
      if (user?.pk) {
        userStore.setMeId(user.pk);
      }
    });
  }


  return meId;
};

export const useMeDetails = () => {
  const id = useMe();
  return useQuery({
    queryKey: computed(() => ["me", id.value]),
    queryFn: () =>
      meetingService
        .getInstance()
        .getUsersApi()
        .usersMeRetrieve({
          uuid: id.value ?? "",
        }),
    staleTime: Infinity,
  });
};

export interface UserRole {
  isDirector: boolean;
  isFormer: boolean;
  isIntern: boolean;
  isSuperuser: boolean;
  detail: NotNestedMeetingRole | null;
}

export const useRole = () => {
  const meetingId = useMeetingId();
  const { data } = useMeDetails();

  const store = useUserStore();

  const { setRole } = store;

  watch(data, () => {
    if (meetingId.value && data.value && data.value.participations) {
      const currentParticipation = data.value.participations.find(
        (p) => p.meeting === meetingId.value,
      );
      if (currentParticipation) {
        setRole(
          {
            isDirector:
              currentParticipation.role.intranet_id === IntranetIdEnum.NUMBER_2,
            isIntern:
              currentParticipation.role.intranet_id === IntranetIdEnum.NUMBER_0,
            isFormer: (
              [
                IntranetIdEnum.NUMBER_1,
                IntranetIdEnum.NUMBER_2,
              ] as unknown as number[]
            ).includes(currentParticipation.role.intranet_id),
            isSuperuser: data.value.is_superuser,
            detail: currentParticipation.role,
          },
          meetingId.value!,
        );
      } else if (data.value.is_superuser) {
        setRole(
          {
            isDirector: false,
            isIntern: false,
            isFormer: true,
            isSuperuser: true,
            detail: null,
          },
          meetingId.value!,
        );
      }
    }
  });

  return computed(() => store.getRoleByMeeting(meetingId.value!));
};

export const useMyParticipation = () => {
  const meetingId = useMeetingId();
  const { data, refetch: internalRefetch, ...rest } = useMeDetails();
  const participation = computed(() => {
    if (meetingId.value && data.value && data.value.participations) {
      return data.value.participations.find(
        (p) => p.meeting === meetingId.value,
      );
    }
  });

  const diplomas = computed(() => data.value?.diplomas);
  const isLoading = computed(() => rest.isLoading.value);

  const refetch = async () => {
    const { data: internalData, ...refetchRest } = await internalRefetch();

    if (meetingId.value && internalData && internalData.participations) {
      const currentParticipation = internalData.participations.find(
        (p) => p.meeting === meetingId.value,
      );
      return {
        participation: currentParticipation,
        diplomas: internalData.diplomas,
        ...refetchRest,
      };
    }
  };

  return {
    participation,
    refetch,
    diplomas,
    ...rest,
    isLoading,
  };
};
